
<template>
   <div class="row">
      <div class="col-2 field-left">{{ oruType }}</div>
      <div class="col-1 field-left">{{ structure }}</div>
      <div class="col-1">
      </div>
      <div class="col-1">
      </div>
      <div class="col-1">
        <FieldDutyCycle :value="dutyCycle" @input="$emit('update:dutyCycle', $event)">
          <input :name="`dutyCycle_$(rowId)`"/>
        </FieldDutyCycle>
      </div>
      <div class="col-1">
      </div>
      <div class="col-1">
      </div>
      <div class="col-1">
      </div>
      <div class="col-1">
      </div>
      <div class="col-1">
      </div>
      <div class="col-1">
      </div>
   </div>
</template>
  
<script>
  import FieldDutyCycle from '@/components/FieldDutyCycle.vue';
  
  export default {
    name: 'DataRow',
    components: {
    FieldDutyCycle,
  },
  props: {
      oruType: String,
      structure: String,
      dutyCycle: Number,
      rowId: {
        type: String,
        default: '0'
      }
    }
}
</script>
  
<style>
.field-left {
  text-align: left;
}
</style>