<template>
    <div class="form-group">
      <select id="flightRate" v-model="flightRate" class="form-control">
        <option v-for="rate in rates" :key="rate" :value="rate">
          {{ rate }}
        </option>
      </select>
    </div>
</template>
  
<script>
  export default {
    name: 'FlightRateSelect',
    data() {
      return {
        rates: [1, 2, 3, 4, 5],
        selectedRate: 1  // Default selected value
      };
    },
    props: {
      value: Number
    },
    watch: {
      value(newVal) {
        this.selectedRate = newVal;
      },
      selectedRate(newVal) {
        this.$emit('input', newVal);
      }
    }
  }
</script>
  