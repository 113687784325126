<template>
    <div>
      <input 
        type="number" 
        v-model="groundSpares" 
        class="form-control" 
        :min="0" 
        :max="100"
        @input="formatNumber"
      >
    </div>
  </template>
  
  <script>
  export default {
    name: 'FieldIntegrationDays',
    props: {
      value: Number
    },
    data() {
      return {
        groundSpares: this.value
      };
    },
    watch: {
      value(newVal) {
        this.groundSpares = newVal;
      },
      groundSpares(newVal) {
        this.$emit('input', newVal);
      }
    },
    methods: {
      formatNumber() {
        this.groundSpares = Number(this.groundSpares).toLocaleString();
      }
    }
  }
  </script>
  