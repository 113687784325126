import FormRowFull from '@/components/FormRowFull.vue';

components: {
    FormRowFull
}

<template>
    <form>
      <h3>Tank Manifolds</h3>
      <FormRowHeader></FormRowHeader>
      <div class="panel panel-shared-1">
        <FormRowNoDuty
          :rowId=1
          :oruType="'TMO Tank Manifold ORU'"
          :structure="'Shared spares'"
          :flightRate="1"
          :integrationDays=30
          :mtbf=10000
          :kFactor=1.0
          :orbitSpares=1
          :groundSpares=1
          :logic="None"
          :repairTime=1
        ></FormRowNoDuty>
        <FormRowDuty
          :rowId=2
          :oruType="'TMO.A Tank Manifold ORU Box A'"
          :structure="'Tank manifold/1'"
          :dutyCycle=100
        ></FormRowDuty>
        <FormRowDuty
          :rowId=3
          :oruType="'TMO.B Tank Manifold ORU Box B'"
          :structure="'Tank manifold/1'"
          :dutyCycle=100
        ></FormRowDuty>
      </div>
      <h3>Potable Water Tanks</h3>
      <FormRowHeader></FormRowHeader>
      <div class="panel panel-neutral">
        <FormRowFull
          :rowId=4
          :oruType="'PWT Potable Water Tank'"
          :structure="'Tank manifold/2'"
          :flightRate="1"
          :integrationDays=30
          :dutyCycle=100
          :mtbf=10000
          :kFactor=1.0
          :orbitSpares=1
          :groundSpares=1
          :logic="None"
          :repairTime=1
        ></FormRowFull>
      </div>
      <h3>Main String</h3>
      <FormRowHeader></FormRowHeader>
      <div class="panel panel-neutral">
        <FormRowFull
          :rowId=5
          :oruType="'DPO Delivery Pump ORU'"
          :structure="'Main string/1'"
          :flightRate="2"
          :integrationDays=30
          :dutyCycle=100
          :mtbf=10000
          :kFactor=1.0
          :orbitSpares=1
          :groundSpares=1
          :logic="None"
          :repairTime=1
        ></FormRowFull>
        <FormRowFull
          :rowId=6
          :oruType="'FIL1 Filter 1'"
          :structure="'Main string/1'"
          :flightRate="3"
          :integrationDays=30
          :dutyCycle=100
          :mtbf=10000
          :kFactor=1.0
          :orbitSpares=1
          :groundSpares=1
          :logic="None"
          :repairTime=1
        ></FormRowFull>
        <FormRowFull
          :rowId=7
          :oruType="'DGSO Degasser ORU'"
          :structure="'Main string/1'"
          :flightRate="3"
          :integrationDays=30
          :dutyCycle=100
          :mtbf=10000
          :kFactor=1.0
          :orbitSpares=1
          :groundSpares=1
          :logic="None"
          :repairTime=1
        ></FormRowFull>
        <FormRowFull
          :rowId=8
          :oruType="'DGS Degasser'"
          :structure="'Main string/1'"
          :flightRate="3"
          :integrationDays=30
          :dutyCycle=100
          :mtbf=10000
          :kFactor=1.0
          :orbitSpares=1
          :groundSpares=1
          :logic="None"
          :repairTime=1
        ></FormRowFull>
        <FormRowFull
          :rowId=9
          :oruType="'VAC Vacuum Pump'"
          :structure="'Main string/1'"
          :flightRate="3"
          :integrationDays=30
          :dutyCycle=100
          :mtbf=10000
          :kFactor=1.0
          :orbitSpares=1
          :groundSpares=1
          :logic="None"
          :repairTime=1
        ></FormRowFull>
        <FormRowFull
          :rowId=10
          :oruType="'BBL Bubble Detector'"
          :structure="'Main string/1'"
          :flightRate="3"
          :integrationDays=30
          :dutyCycle=100
          :mtbf=10000
          :kFactor=1.0
          :orbitSpares=1
          :groundSpares=1
          :logic="None"
          :repairTime=1
        ></FormRowFull>
        <FormRowFull
          :rowId=11
          :oruType="'FILA Filter A'"
          :structure="'Main string/1'"
          :flightRate="3"
          :integrationDays=30
          :dutyCycle=100
          :mtbf=10000
          :kFactor=1.0
          :orbitSpares=1
          :groundSpares=1
          :logic="None"
          :repairTime=1
        ></FormRowFull>
        <FormRowFull
          :rowId=12
          :oruType="'ADO Accumulator Distribution ORU'"
          :structure="'Main string/1'"
          :flightRate="3"
          :integrationDays=30
          :dutyCycle=100
          :mtbf=10000
          :kFactor=1.0
          :orbitSpares=1
          :groundSpares=1
          :logic="None"
          :repairTime=1
        ></FormRowFull>
      </div>
    </form>
    <div class="container">
      <div class="row text">
        <div class="col-md-12 bg-light">
          <button type="button" class="btn btn-primary" @click="exportData">Export</button>
          <!-- <button type="button" class="btn btn-primary" @click="importData">Import</button> -->
        </div>
      </div>
  </div>
    <div>
      <img :src="pdsSchematic" alt="'PDS Schematic'">
    </div>
  </template>
  
  <script>
  import FormRowHeader from '@/components/FormRowHeader.vue';
  import FormRowFull from '@/components/FormRowFull.vue';
  import FormRowNoDuty from './FormRowNoDuty.vue';
  import FormRowDuty from './FormRowDuty.vue';

  export default {
    name: 'PDSPage',
    components: {
    FormRowHeader,
    FormRowFull,
    FormRowNoDuty,
    FormRowDuty
  },
    data() {
      return {
        flightRate: 1,
        pdsSchematic: require('@/assets/PDS.png')
      };
    }
  }
  </script>
  
  <style scoped>
  .panel-shared-1 {
    background-color: #fefcc3;
    border-color: #0a0101;
    margin: 5px;
    padding: 5px;
  }

  .panel-neutral {
    margin: 5px;
    padding: 5px;
  }

  .btn-export {
    margin: 5px;
    padding: 10px;
  }
  </style>

  methods: {
    exportData() {
      const data = this.$refs.form.getData();
      console.log(data);
    }
  }
  