<template>
    <div class="row header-1">
      <div class="col-2">ORU Type</div>
      <div class="col-1">Structure</div>
      <div class="col-1">Flight Rate (f/yr)</div>
      <div class="col-1">Integration Time (days)</div>
      <div class="col-1">Duty Cycle (%)</div>
      <div class="col-1">MTBF (hr)</div>
      <div class="col-1">K Factor</div>
      <div class="col-1">Orbit Spares</div>
      <div class="col-1">Ground Spares</div>
      <div class="col-1">Logic</div>
      <div class="col-1">R/R Time (mo.)</div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'FormRowHeader'
  }
  </script>

  <style scoped>
  .header-1 {
    margin: 5px;
    padding: 5px;
    font-weight: bold;
    background-color: #e9ecef;
  }
  </style>
  