import FormRowFull from '@/components/FormRowFull.vue';

components: {
    FormRowFull
}

<template>
    <form>
      <FormRowHeader></FormRowHeader>
        <FormRowFull
          :rowId=1
          :oruType="'PWT Potable Water Tank'"
          :structure="'Tank manifold/2'"
          :flightRate="1"
          :integrationDays=30
          :dutyCycle=100
          :mtbf=10000
          :kFactor=1.0
          :orbitSpares=1
          :groundSpares=1
          :logic="None"
          :repairTime=1
        ></FormRowFull>
        <FormRowFull
          :rowId=2
          :oruType="'Delivery Pump ORU'"
          :structure="'Main string/1'"
          :flightRate="2"
          :integrationDays=30
          :dutyCycle=100
          :mtbf=10000
          :kFactor=1.0
          :orbitSpares=1
          :groundSpares=1
          :logic="None"
          :repairTime=1
        ></FormRowFull>
        <FormRowFull
          :rowId=3
          :oruType="'Filter 1'"
          :structure="'Main string/1'"
          :flightRate="3"
          :integrationDays=30
          :dutyCycle=100
          :mtbf=10000
          :kFactor=1.0
          :orbitSpares=1
          :groundSpares=1
          :logic="None"
          :repairTime=1
        ></FormRowFull>
    </form>
  </template>
  
  <script>
  import FormRowHeader from '@/components/FormRowHeader.vue';
  import FormRowFull from '@/components/FormRowFull.vue';

  export default {
    name: 'UPSPage',
    components: {
      FormRowHeader,
      FormRowFull
    },
    data() {
      return {
        flightRate: 1
      };
    }
  }
  </script>
  
  <style scoped>
  </style>
  