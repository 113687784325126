
<template>
   <div class="row">
      <div class="col-2 field-left">{{ oruType }}</div>
      <div class="col-1 field-left">{{ structure }}</div>
      <div class="col-1">
        <FlightRateSelect :value="flightRate" @input="$emit('update:flightRate', $event)">
          <input :name="`flightRate_$(rowId)`"/>
        </FlightRateSelect>
      </div>
      <div class="col-1">
        <FieldIntegrationDays :value="integrationDays" @input="$emit('update:integrationDays', $event)">
          <input :name="`integrationDays_$(rowId)`"/>
        </FieldIntegrationDays>
      </div>
      <div class="col-1">
      </div>
      <div class="col-1">
        <FieldMTBF :value="mtbf" @input="$emit('update:mtbf', $event)">
          <input :name="`mtbf_$(rowId)`"/>
        </FieldMTBF>
      </div>
      <div class="col-1">
        <FieldKFactor :value="kFactor" @input="$emit('update:kFactor', $event)">
          <input :name="`kFactor_$(rowId)`"/>
        </FieldKFactor>
      </div>
      <div class="col-1">
        <FieldOrbitSpares :value="orbitSpares" @input="$emit('update:orbitSpares', $event)">
          <input :name="`orbitSpares_$(rowId)`"/>
        </FieldOrbitSpares>
      </div>
      <div class="col-1">
        <FieldGroundSpares :value="groundSpares" @input="$emit('update:groundSpares', $event)">
          <input :name="`groundSpares_$(rowId)`"/>
        </FieldGroundSpares>
      </div>
      <div class="col-1">
        <LogicSelect :value="logic" @input="$emit('update:logic', $event)">
          <input :name="`logic_$(rowId)`"/>
        </LogicSelect>
      </div>
      <div class="col-1">
        <FieldRepairTime :value="repairTime" @input="$emit('update:repairTime', $event)">
          <input :name="`repairTime_$(rowId)`"/>
        </FieldRepairTime>
      </div>
   </div>
</template>
  
<script>
  import FlightRateSelect from '@/components/FlightRateSelect.vue';
  import LogicSelect from '@/components/LogicSelect.vue';
  import FieldIntegrationDays from '@/components/FieldIntegrationDays.vue';
  import FieldMTBF from '@/components/FieldMTBF.vue';
  import FieldKFactor from '@/components/FieldKFactor.vue';
  import FieldOrbitSpares from '@/components/FieldOrbitSpares.vue';
  import FieldGroundSpares from '@/components/FieldGroundSpares.vue';
  import FieldRepairTime from '@/components/FieldRepairTime.vue';
  
  export default {
    name: 'DataRow',
    components: {
    FlightRateSelect,
    FieldIntegrationDays,
    LogicSelect,
    FieldMTBF,
    FieldKFactor,
    FieldOrbitSpares,
    FieldGroundSpares,
    FieldRepairTime
  },
  props: {
      oruType: String,
      structure: String,
      flightRate: Number,
      integrationDays: Number,
      mtbf: Number,
      kFactor: Number,
      orbitSpares: Number,
      groundSpares: Number,
      logic: String,
      repairTime: Number,
      rowId: {
        type: String,
        default: '0'
      }
    }
}
</script>
  
<style>
.field-left {
  text-align: left;
}
</style>