<template>
    <div>
      <input 
        type="number" 
        v-model="repairTime" 
        class="form-control" 
        :min="0"
        :max="1000"
        @input="formatNumber"
      >
    </div>
  </template>
  
  <script>
  export default {
    name: 'FieldRepairTime',
    props: {
      value: Number
    },
    data() {
      return {
        repairTime: this.value
      };
    },
    watch: {
      value(newVal) {
        this.repairTime = newVal;
      },
      repairTime(newVal) {
        this.$emit('input', newVal);
      }
    },
    methods: {
      formatNumber() {
        this.repairTime = Number(this.repairTime).toLocaleString();
      }
    }
  }
  </script>
  