import { createRouter, createWebHistory } from 'vue-router'
import PDSPage from '@/components/PDSPage.vue'
import UPSPage from '@/components/UPSPage.vue'
// import HomePage from '@/components/HomePage.vue'

const routes = [
  // {
  //   path: '/',
  //   name: 'home',
  //   component: HomePage
  // },
  {
    path: '/pds',
    name: 'PDS',
    component: PDSPage
  },
  {
     path: '/ups',
     name: 'UPS',
     component: UPSPage
   },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
