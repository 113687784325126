<template>
    <div class="form-group">
      <select id="logic" v-model="logic" class="form-control">
        <option v-for="mode in modes" :key="mode" :value="mode">
          {{ mode }}
        </option>
      </select>
    </div>
</template>
  
<script>
  export default {
    name: 'LogicSelect',
    data() {
      return {
        modes: ['None','Repair','Replace'],
        selectedMode: 'None'  // Default selected value
      };
    },
    props: {
      value: String
    },
    watch: {
      value(newVal) {
        this.selectedMode = newVal;
      },
      selectedMode(newVal) {
        this.$emit('input', newVal);
      }
    }
  }
</script>
  